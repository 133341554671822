<template>
    <div class="calendar-article">
        <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/categories' }">Categories</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: `/ovalspace/os-admin/rooms/${$route.params.categoryID}` }">Rooms</el-breadcrumb-item>
                    <el-breadcrumb-item>Calendar</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <FullCalendar :options='calendarOptions'>
            <template v-slot:eventContent='arg'>
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
            </template>
        </FullCalendar>
    </div>
  </template>
  
  <script>
    // import FullCalendar from 'vue-full-calendar' 
    // import 'fullcalendar/dist/fullcalendar.css'; // Import CSS for FullCalendar categoryID

    import FullCalendar from '@fullcalendar/vue';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid'
    import interactionPlugin from '@fullcalendar/interaction'


    // import VueCal from 'vue-cal';
    // import 'vue-cal/dist/vuecal.css'; // import CSS
  
    export default {
        components: {
            FullCalendar,
            // VueCal
        },
        data() {
        return {
            loading: false,
            isLoading: false,
            is_changing: false,
            // calendarOptions: {
            //     plugins: [dayGridPlugin],
            //     initialView: 'dayGridMonth'
            // },
            calendarOptions: {
                plugins: [
                    dayGridPlugin, 
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                        
                initialView: 'dayGridMonth',
                // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
                events: [{
                    title: 'Static Event 1',
                    start: '2024-09-22',
                    end: '2024-09-23'
                }]
            },
            // events: [], // or your fetched events
            events: [
                {
                    title: 'Static Event 1',
                    start: '2024-09-22',
                    end: '2024-09-23'
                }
            ],
            newEvent: { title: '', startDate: '', endDate: '' },
            showModal: false
        };
        },

        mounted() {
        this.fetchEvents();
        },

        methods: {
            onCellClick(date) {
                this.showModal = true;
                this.newEvent.startDate = date;
            },

            async fetchEvents() {
                this.isLoading = true;
                this.isLoadingError = false;
                try {
                    //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
                    let request = await this.$http.get(`events/get-room-event-only/${this.$route.params.roomID}`);
                    if (request.data.success && request.data.message === "EVENTS_FETCHED_SUCCESSFULLY") {
                        this.calendarOptions.events = request.data.events.map(event => ({
                            title: event.title,
                            start: event.start,
                            end: event.end
                        }));
                    } else throw "UNEXPECTED_RESPONSE_RECEIVED";
                } catch (error) {
                    this.isLoadingError = true;
                    //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
                    this.$notify({
                    title: "Operation Failed",
                    message: "Unable to fetch Events now, please try again",
                    type: "error",
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            async saveEvent() {
                this.isLoading = true;
                this.isLoadingError = false;
                this.fetchEvents();
                this.showModal = false;
                try {
                    //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
                    let request = await this.$http.post(`events`, {
                        title: this.newEvent.title,
                        startDate: this.newEvent.startDate,
                        endDate: this.newEvent.endDate,
                    });
                    if (request.data.success) {
                        this.$notify({
                            title: "Success",
                            message: "City added successfully",
                            type: "success",
                        });
                    } else throw "UNEXPECTED_RESPONSE_RECEIVED";
                } catch (error) {
                    this.isLoadingError = true;
                    //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
                    this.$notify({
                    title: "Operation Failed",
                    message: "Unable to Add Events now, please try again",
                    type: "error",
                    });
                } finally {
                    this.isLoading = false;
                }
            },
                
            editEvent(event) {
                // logic for editing events
            },
            openCreateModal() {
                this.showModal = true;
            }
        }
    };
  </script>
  
<style scoped> 
    .calendar-article {
        padding-top: 50px;
    }
</style>

<!-- <style>
    @import "/fullcalendar/core/main.css";
    @import "/fullcalendar/daygrid/main.css";
</style> -->
  